import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  IdoListType,
  NetworkAPIType,
  TokenAPIType,
  WhitelistAddress,
} from "../utils";
import { CloseOutlined } from "@ant-design/icons";

import { onKeyPress } from "./Create";
import {
  Button,
  Col,
  ConfigProvider,
  DatePicker,
  Form,
  Input,
  Layout,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Upload,
  message,
} from "antd";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import Text from "../components/Text";
import { Networks, Tokens, EditIdo } from "../api/ApiCalls";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import deleteOutlined from "../assets/DeleteOutlined.svg";
import { DeleteSingleIdo } from "../api/ApiCalls/CreateIDO";
import { o } from "@tanstack/query-core/build/legacy/queryClient-6vLRMq5C";
import DeleteModal from "../components/DeleteModal";

const EditIDO = () => {
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  const location = useLocation();
  const obj: IdoListType = location.state?.obj;
  const { TextArea } = Input;
  const navigate = useNavigate();
  const { Option } = Select;
  const [network, setNetwork] = useState<NetworkAPIType[]>([]);
  const [token, setToken] = useState<TokenAPIType[]>([]);
  const [deleteLoader, setDeleteLoader] = useState<boolean>(false);
  const [tokenFile, setTokenFile] = useState<File>();
  const [projectFile, setProjectFile] = useState<File>();
  const [tokenFlage, setTokenFlage] = useState<boolean>(true);
  const [projectFlage, setProjectFlage] = useState<boolean>(true);
  const [editLoading, setEditLoading] = useState<boolean>(false);
  const [TGEflage, setTGEflage] = useState<boolean>(true);
  const [tokenSuffix, setTokenSuffix] = useState<string>(obj?.token?.symbol);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showDeleteModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleFileChange = (info: any) => {
    const fileList = [...info.fileList];
    setTokenFile(fileList.length > 0 ? fileList[0].originFileObj : null);
    setTokenFlage(false);
  };

  const handleProjectFileChange = (info: any) => {
    const fileList = [...info.fileList];
    setProjectFile(fileList.length > 0 ? fileList[0].originFileObj : null);
    setProjectFlage(false);
  };

  const GetTokens = async () => {
    try {
      const res = await Tokens();
      if (res) {
        // console.log("Token API res ", res);
        setToken(res);
      }
    } catch (err) {
      console.log("Error While Fetch Token API", err);
    }
  };

  const GetNetworks = async () => {
    try {
      const res = await Networks();
      if (res) {
        //console.log("Networks API res ", res);
        setNetwork(res);
      }
    } catch (err) {
      console.log("Error While Fetch Networks API", err);
    }
  };

  useEffect(() => {
    GetTokens();
    GetNetworks();
  }, []);

  const HandleDelete = async (id: number) => {
    setDeleteLoader(true);
    try {
      const res = await DeleteSingleIdo(id);

      if (res) {
        message.success("IDO deleted");
        navigate("/");
        setDeleteLoader(false);
      }
    } catch (err) {
      console.log("Error While delete IDO", err);
      message.error("Error While Deleting IDO!");
      setDeleteLoader(false);
    }
  };

  const EditIdoFunction = async (
    id: number,
    formData: any,
    changedFields: Array<string>
  ) => {
    setEditLoading(true);
    try {
      const ido_stage: any = obj.stage;
      const forbiddenFields: any = {
        interest: ["interest_start"],
        allocation: ["interest_start", "interest_end", "allocation_start"],
        fcfs: [
          "interest_start",
          "interest_end",
          "allocation_start",
          "allocation_end",
          "fcfs_start",
        ],
        ended: [
          "interest_start",
          "interest_end",
          "allocation_start",
          "allocation_end",
          "fcfs_start",
          "fcfs_end",
        ],
      };

      if (forbiddenFields[ido_stage]) {
        const hasForbiddenField = forbiddenFields[ido_stage].some(
          (field: any) => changedFields.includes(field)
        );
        if (hasForbiddenField) {
          const errorMessage = `Cannot edit ${forbiddenFields[ido_stage]
            .join(", ")
            .replace("_", " ")} at this stage`;
          message.error(errorMessage);
          setEditLoading(false);
          return;
        }
      }

      const res = await EditIdo(id.toString(), formData);
      if (res) {
        message.success("IDO Updated!");

        setTimeout(() => {
          navigate("/");
          setEditLoading(false);
        }, 1000);
      }
      setEditLoading(false);
    } catch (err: any) {
      console.log("Error while Edit Ido ", err);
      message.error("error", err.response.data.error);
      setEditLoading(false);
    }
  };

  const onChange = (e: RadioChangeEvent) => {
    console.log("radio checked", e.target.value);
    setTGEflage(e.target.value);
  };

  const onFinish = (values: FormValues) => {
    const formData = new FormData();
    const changedFields: string[] = [];

    const compareAndTrackChanges = (
      fieldName: string,
      newValue: any,
      initialValue: any
    ) => {
      if (newValue !== initialValue) {
        if (newValue) {
          if (fieldName === "exchange_tokens") {
            newValue.forEach((tokenId: string) => {
              formData.append(fieldName, tokenId);
            });
          } else {
            formData.append(fieldName, newValue);
          }
          changedFields.push(fieldName);
        }
      }
    };

    compareAndTrackChanges(
      "amount",
      values.amount?.toString() || "",
      obj.amount
    );
    compareAndTrackChanges("price", values.price.toString() || "", obj.price);
    compareAndTrackChanges(
      "token_name",
      values.token_name || "",
      obj.token_name
    );
    compareAndTrackChanges(
      "wallet_address",
      values.wallet_address || "",
      obj.wallet_address
    );
    compareAndTrackChanges("network", values.network || "", obj.network.id);
    compareAndTrackChanges("token", values.token || "", obj.token.id);

    compareAndTrackChanges(
      "exchange_tokens",
      values.exchange_tokens || "",
      obj.exchange_tokens
    );
    compareAndTrackChanges("valuation", values.valuation || "", obj.valuation);
    compareAndTrackChanges(
      "total_supply",
      values.total_supply || "",
      obj.total_supply
    );
    compareAndTrackChanges(
      "initial_market_cap",
      values.initial_market_cap || "",
      obj.initial_market_cap
    );
    compareAndTrackChanges("hard_cap", values.hard_cap || "", obj.hard_cap);
    compareAndTrackChanges(
      "platform_raise",
      values.platform_raise || "",
      obj.platform_raise
    );
    compareAndTrackChanges(
      "TGEpercentage",
      values.TGEpercentage || "",
      obj.tge?.tge_percentage
    );
    compareAndTrackChanges(
      "tokenReleaseAmount",
      values.tokenReleaseAmount || "",
      obj.total_release_months
    );

    compareAndTrackChanges(
      "interest_start",
      formatDateToUTC(values?.interest_start?.toDate().toUTCString()) || "",
      obj.interest_start
    );
    compareAndTrackChanges(
      "interest_end",
      formatDateToUTC(values?.interest_end?.toDate()) || "",
      obj.interest_end
    );
    compareAndTrackChanges(
      "allocation_start",
      formatDateToUTC(values?.allocation_start?.toDate()) || "",
      obj.allocation_start
    );

    compareAndTrackChanges(
      "allocation_end",
      formatDateToUTC(values?.allocation_end?.toDate()) || "",
      obj.allocation_end
    );
    compareAndTrackChanges(
      "fcfs_start",
      formatDateToUTC(values?.fcfs_start?.toDate()) || "",
      obj.fcfs_start
    );
    compareAndTrackChanges(
      "fcfs_end",
      formatDateToUTC(values?.fcfs_end?.toDate()) || "",
      obj.fcfs_end
    );
    compareAndTrackChanges(
      "token_intro",
      values.token_intro || "",
      obj.token_intro
    );
    compareAndTrackChanges(
      "project_overview",
      values.project_overview || "",
      obj.project_overview
    );
    compareAndTrackChanges(
      "revenue_model",
      values.revenue_model || "",
      obj.revenue_model
    );
    compareAndTrackChanges("website", values.website || "", obj.website);
    compareAndTrackChanges(
      "linked_in_url",
      values.linked_in_url || "",
      obj.linked_in_url
    );
    compareAndTrackChanges("x_url", values.x_url || "", obj.x_url);
    compareAndTrackChanges(
      "telegram_url",
      values.telegram_url || "",
      obj.telegram_url
    );
    compareAndTrackChanges(
      "discord_url",
      values.discord_url || "",
      obj.discord_url
    );

    // if (tokenFile) {
    //   formData.append("token_image", tokenFile);
    // }
    if (projectFile) {
      formData.append("project_image", projectFile);
    }

    //////////---------------------------------///////
    if (TGEflage) {
      compareAndTrackChanges(
        "tge_percentage",
        values.TGEpercentage,
        obj?.tge?.tge_percentage
      );

      compareAndTrackChanges(
        "tge_date",
        formatDateToUTC(values?.TgeDate?.toDate().toUTCString()),
        obj?.tge?.tge_date
      );

      compareAndTrackChanges(
        "acquire_fee",
        values.acquireFee || "",
        obj.acquire_fee
      );

      // formData.append("tge_percentage", values.TGEpercentage);
      // formData.append("tge_date", formatDate(values?.TgeDate?.toDate()));
    }

    compareAndTrackChanges(
      "monthly_unlock_start",
      values?.monthlyUnlockStart || "",
      obj.monthly_unlock_start
    );
    compareAndTrackChanges(
      "total_release_months",
      values.tokenReleaseAmount,
      obj.total_release_months
    );

    EditIdoFunction(obj.id, formData, changedFields);
  };

  const handleTokenChange = (tokenid: string) => {
    const selectedToken = token.find((t) => t.id === tokenid);

    if (selectedToken) {
      setTokenSuffix(selectedToken.symbol);
    }
  };

  return (
    <Layout
      style={{
        backgroundColor: "#0C0A1F",
        alignItems: "center",
        padding: md ? "36px 20px" : sm ? "24px 24px" : " 24px 16px",
      }}
    >
      <Row
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "#120f2d",
          borderRadius: "12px",
          padding: sm ? "56px" : "16px",
          overflowY: "auto",
        }}
      >
        <Row style={{ maxWidth: "650px", width: "100%" }}>
          <Form
            name="step3"
            style={{ width: "100%" }}
            initialValues={{
              amount: obj.amount,
              price: obj.price,
              token_name: obj.token_name,
              wallet_address: obj.wallet_address,
              network: obj.network.id,
              token: obj.token.send_token === false ? obj.token.id : null,
              exchange_tokens: obj.exchange_tokens.map((token) => token.id),
              valuation: obj.valuation,
              total_supply: obj.total_supply,
              initial_market_cap: obj.initial_market_cap,
              hard_cap: obj.hard_cap,
              platform_raise: obj.platform_raise,
              TGEpercentage: obj?.tge?.tge_percentage,
              acquireFee: obj?.acquire_fee,
              tokenReleaseAmount: obj.total_release_months,

              TgeDate: obj.tge
                ? obj?.tge?.tge_date === "" || null
                  ? null
                  : dayjs(moment(obj?.tge?.tge_date)?.toDate())
                : null,

              // interest_start: dayjs(moment(obj.token_intro).toDate()),
              monthlyUnlockStart: obj.monthly_unlock_start,

              interest_start:
                obj.interest_start === null
                  ? null
                  : dayjs(moment(obj.interest_start).toDate()),

              interest_end:
                obj.interest_end === null
                  ? null
                  : dayjs(moment(obj.interest_end).toDate()),
              allocation_start:
                obj.allocation_start === null
                  ? null
                  : dayjs(moment(obj.allocation_start).toDate()),
              allocation_end:
                obj.allocation_end === null
                  ? null
                  : dayjs(moment(obj.allocation_end).toDate()),
              fcfs_start:
                obj.fcfs_start === null
                  ? null
                  : dayjs(moment(obj.fcfs_start).toDate()),
              fcfs_end:
                obj.fcfs_end === null
                  ? null
                  : dayjs(moment(obj.fcfs_end).toDate()),

              token_intro: obj.token_intro,
              project_overview: obj.project_overview,
              revenue_model: obj.revenue_model,
              website: obj.website,
              linked_in_url: obj.linked_in_url,
              x_url: obj.x_url,
              telegram_url: obj.telegram_url,
              discord_url: obj.discord_url,
              //token_image: null,
              //project_image: null,
            }}
            onFinish={onFinish}
          >
            <Text size="md">IDO information</Text>

            <Form.Item
              style={{ marginTop: "12px" }}
              name="token_name"
              rules={[
                {
                  required: true,
                  message: "Please input token name!",
                },
              ]}
            >
              <Input
                placeholder="Enter a name"
                size="large"
                addonAfter={<AddonText text="Offer name" />}
              />
            </Form.Item>

            <Row style={{ flexDirection: "column", gap: "4px" }}>
              <Text size="xs" style={{ color: "#909090" }}>
                User Receive token
              </Text>
              <Form.Item
                // label="Exchange Token"
                name="token"
                rules={[{ required: true, message: "Please select token!" }]}
              >
                <Select
                  placeholder="Select a token"
                  size="large"
                  onChange={handleTokenChange}
                >
                  {token
                    .filter((token) => token.send_token === false) // Filter tokens where send_token is false
                    .map((token) => (
                      <Option key={token.id} value={token.id}>
                        {token.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Row>

            <Form.Item
              name="amount"
              rules={[
                {
                  required: true,
                  message: "Please input total amount!",
                },
              ]}
            >
              <Input
                placeholder="0.00"
                size="large"
                suffix={tokenSuffix}
                addonAfter={<AddonText text="Tokens To Sell" />}
                onKeyPress={(e) => {
                  const charCode = e.which ? e.which : e.keyCode;
                  const value = (e.target as HTMLInputElement).value;

                  if (
                    (charCode !== 46 || value.indexOf(".") !== -1) &&
                    (charCode < 48 || charCode > 57)
                  ) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>

            <Form.Item
              name="price"
              rules={[
                {
                  required: true,
                  message: "Please input price!",
                },
              ]}
            >
              <Input
                placeholder="0.00"
                size="large"
                addonAfter={<AddonText text="Launch Price" />}
                onKeyPress={(e) => {
                  const charCode = e.which ? e.which : e.keyCode;
                  const value = (e.target as HTMLInputElement).value;

                  if (
                    (charCode !== 46 || value.indexOf(".") !== -1) &&
                    (charCode < 48 || charCode > 57)
                  ) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>

            <Form.Item
              name="wallet_address"
              rules={[
                {
                  required: true,
                  message: "Please input Wallet address!",
                },
              ]}
            >
              <Input
                placeholder="Enter a wallet address"
                size="large"
                addonAfter={<AddonText text="Deposit Address" />}
              />
            </Form.Item>

            <Row style={{ flexDirection: "column", gap: "4px" }}>
              <Text size="xs" style={{ color: "#909090" }}>
                Specify output wallet chain
              </Text>
              <Form.Item
                name="network"
                rules={[{ required: true, message: "Please select network!" }]}
              >
                <Select placeholder="Select a network" size="large">
                  {network.map((token) => (
                    <Option key={token.name} value={token.id}>
                      {token.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Row>

            <Row style={{ flexDirection: "column", gap: "4px" }}>
              <Text size="xs" style={{ color: "#909090" }}>
                Valid Stable Coins
              </Text>
              <Form.Item
                name="exchange_tokens"
                rules={[
                  {
                    required: true,
                    message: "Please select at least one token!",
                  },
                ]}
              >
                <Select
                  placeholder="Select tokens"
                  size="large"
                  mode="multiple"
                  showSearch
                  optionFilterProp="children"
                  defaultValue={obj.exchange_tokens.map((token) => token.id)}
                >
                  {token
                    .filter((token) => token.send_token === true)
                    .map((token) => (
                      <Option key={token.id} value={token.id}>
                        {token.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Row>

            {/* <Row style={{ flexDirection: "column", gap: "4px" }}>
              <Text size="xs" style={{ color: "#909090" }}>
                Valid Stable Coin
              </Text>
              <Form.Item
                // label="Exchange Token"
                name="exchange_tokens"
                rules={[{ required: true, message: "Please select token!" }]}
              >
                <Select placeholder="Select a token" size="large">
                  {token
                    .filter((token) => token.send_token === true)
                    .map((token) => (
                      <Option key={token.id} value={token.id}>
                        {token.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Row> */}

            <Row gutter={24}>
              <Col span={12}>
                <Row style={{ flexDirection: "column", gap: "4px" }}>
                  <Text size="xs" style={{ color: "#909090" }}>
                    Interest Start time
                  </Text>
                  <Form.Item
                    name="interest_start"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please pick start time",
                    //   },
                    // ]}
                  >
                    <DatePicker
                      placeholder="Start Date"
                      showTime
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Row>
              </Col>
              <Col span={12}>
                <Row style={{ flexDirection: "column", gap: "4px" }}>
                  <Text size="xs" style={{ color: "#909090" }}>
                    Interest End time
                  </Text>
                  <Form.Item
                    name="interest_end"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please pick end time",
                    //   },
                    // ]}
                  >
                    <DatePicker
                      placeholder="End Date"
                      showTime
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Row>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={12}>
                <Row style={{ flexDirection: "column", gap: "4px" }}>
                  <Text size="xs" style={{ color: "#909090" }}>
                    Allocation Start time
                  </Text>
                  <Form.Item
                    name="allocation_start"
                    rules={[
                      {
                        required: true,
                        message: "Please pick start time",
                      },
                    ]}
                  >
                    <DatePicker
                      placeholder="Start Date"
                      showTime
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Row>
              </Col>
              <Col span={12}>
                <Row style={{ flexDirection: "column", gap: "4px" }}>
                  <Text size="xs" style={{ color: "#909090" }}>
                    Allocation End time
                  </Text>
                  <Form.Item
                    name="allocation_end"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please pick end time",
                    //   },
                    // ]}
                  >
                    <DatePicker
                      placeholder="End Date"
                      showTime
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Row>
              </Col>
            </Row>

            <Row gutter={24} style={{ marginBottom: "12px" }}>
              <Col span={12}>
                <Row style={{ flexDirection: "column", gap: "4px" }}>
                  <Text size="xs" style={{ color: "#909090" }}>
                    FCFS Start time
                  </Text>
                  <Form.Item
                    name="fcfs_start"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please pick start time",
                    //   },
                    // ]}
                  >
                    <DatePicker
                      placeholder="Start Date"
                      showTime
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Row>
              </Col>
              <Col span={12}>
                <Row style={{ flexDirection: "column", gap: "4px" }}>
                  <Text size="xs" style={{ color: "#909090" }}>
                    FCFS End time
                  </Text>
                  <Form.Item
                    name="fcfs_end"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please pick end time",
                    //   },
                    // ]}
                  >
                    <DatePicker
                      placeholder="End Date"
                      showTime
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Row>
              </Col>
            </Row>

            <Text size="md">Project information</Text>

            <Row
              style={{
                gap: "8px",
                flexDirection: "column",
                marginBottom: "20px",
                marginTop: "12px",
              }}
            >
              <Text size="xs" style={{ color: "#909090" }}>
                Project image
              </Text>
              <Form.Item
                //name=""
                // rules={[
                //   {
                //     required: true,
                //     message: "Please upload Image",
                //   },
                // ]}
                style={{ width: "100%" }}
              >
                <Upload
                  onChange={handleProjectFileChange}
                  beforeUpload={() => false}
                >
                  <Button icon={<UploadOutlined />} style={{ width: "100%" }}>
                    Upload
                  </Button>
                </Upload>
              </Form.Item>
              {obj.project_image && projectFlage && (
                <Col
                  style={{
                    width: "100%",
                    border: "1px solid #424242",
                    borderRadius: "6px",
                    padding: "8px",
                    marginTop: "-8px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Col
                    style={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                    }}
                  >
                    <img
                      style={{
                        width: "36px",
                        height: "36px",
                        borderRadius: "50%",
                      }}
                      src={obj.project_image}
                    />
                    {/* <Text size="sm" style={{ color: "#33FFFF" }}>
                    {obj.project_image}
                  </Text> */}
                  </Col>
                  <img
                    src={deleteOutlined}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setProjectFlage(false);
                    }}
                  />
                </Col>
              )}
            </Row>

            <Form.Item
              name="valuation"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please input Valuation!",
              //   },
              // ]}
            >
              <Input
                placeholder="0.00"
                size="large"
                addonAfter={<AddonText text="Valuation" />}
                onKeyPress={(e) => {
                  const charCode = e.which ? e.which : e.keyCode;
                  const value = (e.target as HTMLInputElement).value;

                  if (
                    (charCode !== 46 || value.indexOf(".") !== -1) &&
                    (charCode < 48 || charCode > 57)
                  ) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>

            <Form.Item
              name="total_supply"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please input total supply!",
              //   },
              // ]}
            >
              <Input
                placeholder="0.00"
                size="large"
                addonAfter={<AddonText text="Total supply" />}
                onKeyPress={(e) => {
                  const charCode = e.which ? e.which : e.keyCode;
                  const value = (e.target as HTMLInputElement).value;

                  if (
                    (charCode !== 46 || value.indexOf(".") !== -1) &&
                    (charCode < 48 || charCode > 57)
                  ) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>

            <Form.Item
              name="initial_market_cap"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please input initial market cap!",
              //   },
              // ]}
            >
              <Input
                placeholder="0.00"
                size="large"
                addonAfter={<AddonText text="Initial Market cap" />}
                onKeyPress={(e) => {
                  const charCode = e.which ? e.which : e.keyCode;
                  const value = (e.target as HTMLInputElement).value;

                  if (
                    (charCode !== 46 || value.indexOf(".") !== -1) &&
                    (charCode < 48 || charCode > 57)
                  ) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>

            <Form.Item
              name="hard_cap"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please input hard cap!",
              //   },
              // ]}
            >
              <Input
                placeholder="0.00"
                size="large"
                addonAfter={<AddonText text="Allocation Max" />}
                onKeyPress={(e) => {
                  const charCode = e.which ? e.which : e.keyCode;
                  const value = (e.target as HTMLInputElement).value;

                  if (
                    (charCode !== 46 || value.indexOf(".") !== -1) &&
                    (charCode < 48 || charCode > 57)
                  ) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>

            <Form.Item
              name="platform_raise"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please input platform raise!",
              //   },
              // ]}
            >
              <Input
                placeholder="0.00"
                size="large"
                addonAfter={<AddonText text="Platform raise" />}
                onKeyPress={(e) => {
                  const charCode = e.which ? e.which : e.keyCode;
                  const value = (e.target as HTMLInputElement).value;

                  if (
                    (charCode !== 46 || value.indexOf(".") !== -1) &&
                    (charCode < 48 || charCode > 57)
                  ) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>

            <Row style={{ flexDirection: "column", gap: "8px" }}>
              <Text size="xs" style={{ color: "#909090" }}>
                Token intro text
              </Text>
              <Form.Item
                name="token_intro"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please input into text",
                //   },
                // ]}
                style={{ width: "100%" }}
              >
                <TextArea rows={4} placeholder="Enter a intro text" />
              </Form.Item>
            </Row>

            <Row style={{ flexDirection: "column", gap: "8px" }}>
              <Text size="xs" style={{ color: "#909090" }}>
                Project overview
              </Text>
              <Form.Item
                name="project_overview"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please input project overview",
                //   },
                // ]}
                style={{ width: "100%" }}
              >
                <TextArea
                  rows={4}
                  placeholder="Enter a project overview text"
                />
              </Form.Item>
            </Row>

            <Row style={{ flexDirection: "column", gap: "8px" }}>
              <Text size="xs" style={{ color: "#909090" }}>
                Revenue model
              </Text>
              <Form.Item
                name="revenue_model"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please input revenue",
                //   },
                // ]}
                style={{ width: "100%" }}
              >
                <TextArea rows={4} placeholder="Enter a revenue model text" />
              </Form.Item>
            </Row>

            <Row style={{ flexDirection: "column", gap: "8px" }}>
              <Text size="xs" style={{ color: "#909090" }}>
                Website
              </Text>
              <Form.Item
                name="website"
                rules={[
                  {
                    validator: validateDiscordUrl,
                  },
                ]}
              >
                <Input placeholder="Enter a website url" size="large" />
              </Form.Item>
            </Row>

            <Row style={{ flexDirection: "column", gap: "8px" }}>
              <Text size="xs" style={{ color: "#909090" }}>
                LinkedIn
              </Text>
              <Form.Item
                name="linked_in_url"
                rules={[
                  {
                    validator: validateDiscordUrl,
                  },
                ]}
              >
                <Input placeholder="Enter a LinkedIn url" size="large" />
              </Form.Item>
            </Row>

            <Row style={{ flexDirection: "column", gap: "8px" }}>
              <Text size="xs" style={{ color: "#909090" }}>
                X
              </Text>
              <Form.Item
                name="x_url"
                rules={[
                  {
                    validator: validateDiscordUrl,
                  },
                ]}
              >
                <Input placeholder="Enter a X url" size="large" />
              </Form.Item>
            </Row>

            <Row style={{ flexDirection: "column", gap: "8px" }}>
              <Text size="xs" style={{ color: "#909090" }}>
                Telegram
              </Text>
              <Form.Item
                name="telegram_url"
                rules={[
                  {
                    validator: validateDiscordUrl,
                  },
                ]}
              >
                <Input placeholder="Enter a Telegram url" size="large" />
              </Form.Item>
            </Row>

            <Row style={{ flexDirection: "column", gap: "8px" }}>
              <Text size="xs" style={{ color: "#909090" }}>
                Discord
              </Text>
              <Form.Item
                name="discord_url"
                rules={[
                  {
                    validator: validateDiscordUrl,
                  },
                ]}
              >
                <Input placeholder="Enter a Discord url" size="large" />
              </Form.Item>
            </Row>

            <Text size="md" style={{ fontWeight: "600" }}>
              Payouts
            </Text>

            <Row
              style={{
                flexDirection: "column",
                gap: "4px",
                width: "100%",
                marginTop: "12px",
              }}
            >
              <Text size="xs" style={{ color: "#909090" }}>
                Acquire Fee (%)
              </Text>
              <Form.Item
                name="acquireFee"
                rules={[
                  {
                    required: true,
                    message: "Please input Acquire Fee!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value >= 1 && value <= 100) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("The value must be between 1 and 100!")
                      );
                    },
                  }),
                ]}
              >
                <Input
                  placeholder="0"
                  size="large"
                  addonAfter={<AddonText text="%" small />}
                  onKeyPress={(e) => {
                    const charCode = e.which ? e.which : e.keyCode;
                    const value = (e.target as HTMLInputElement).value;

                    if (
                      (charCode !== 46 || value.indexOf(".") !== -1) &&
                      (charCode < 48 || charCode > 57)
                    ) {
                      e.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Row>
            {obj.tge && (
              <>
                <Row style={{ marginBottom: "16px", marginTop: "12px" }}>
                  <Text
                    size="xs"
                    style={{
                      color: "#909090",
                      width: "100%",

                      marginBottom: "4px",
                    }}
                  >
                    Do you want to include a TGE payout release?
                  </Text>
                  <Radio.Group onChange={onChange} value={TGEflage}>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Row>

                {TGEflage === true && (
                  <Row style={{ flexDirection: "column", gap: "4px" }}>
                    <Text size="xs" style={{ color: "#909090" }}>
                      TGE percentage
                    </Text>
                    <Form.Item
                      name="TGEpercentage"
                      rules={[
                        {
                          required: TGEflage,
                          message: "Please input initial market cap!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="0"
                        size="large"
                        addonAfter={<AddonText text="%" small />}
                        onKeyPress={(e) => {
                          const charCode = e.which ? e.which : e.keyCode;
                          const value = (e.target as HTMLInputElement).value;

                          if (
                            (charCode !== 46 || value.indexOf(".") !== -1) &&
                            (charCode < 48 || charCode > 57)
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>
                  </Row>
                )}

                {TGEflage === true && (
                  <Row style={{ flexDirection: "column", gap: "4px" }}>
                    <Text size="xs" style={{ color: "#909090" }}>
                      TGE date
                    </Text>
                    <Form.Item
                      name="TgeDate"
                      rules={[
                        {
                          required: TGEflage,
                          message: "Please pick start time",
                        },
                      ]}
                    >
                      <DatePicker
                        placeholder="Date"
                        showTime
                        style={{ width: "100%" }}
                        size="large"
                      />
                    </Form.Item>
                  </Row>
                )}
              </>
            )}

            <Row
              style={{ flexDirection: "column", gap: "4px", marginTop: "12px" }}
            >
              <Text size="xs" style={{ color: "#909090" }}>
                Monthly unlock start (Cliff)
              </Text>
              <Form.Item
                name="monthlyUnlockStart"
                rules={[
                  {
                    required: true,
                    message: "Input Required!",
                  },
                ]}
              >
                <Input
                  placeholder="0"
                  size="large"
                  addonAfter={<AddonText text="Months" medium />}
                  type="number"
                  onKeyPress={(e) => {
                    const charCode = e.which ? e.which : e.keyCode;
                    const value = (e.target as HTMLInputElement).value;
                  }}
                />
              </Form.Item>
            </Row>

            <Row style={{ flexDirection: "column", gap: "4px" }}>
              <Text size="xs" style={{ color: "#909090" }}>
                Amount of months to release tokens
              </Text>
              <Form.Item
                name="tokenReleaseAmount"
                rules={[
                  {
                    required: true,
                    message: "Input Required!",
                  },
                ]}
              >
                <Input
                  placeholder="0"
                  size="large"
                  addonAfter={<AddonText text="Months" medium />}
                  onKeyPress={(e) => {
                    const charCode = e.which ? e.which : e.keyCode;
                    const value = (e.target as HTMLInputElement).value;

                    if (
                      (charCode !== 46 || value.indexOf(".") !== -1) &&
                      (charCode < 48 || charCode > 57)
                    ) {
                      e.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Row>

            {/* ////////////////////////////////////////////WhiteList////////////////////////////////////////////////// */}
            <Text size="md">Whitelisted Wallets</Text>
            <Row style={{ marginTop: "12px", width: "100%" }}>
              <Form.List
                name="whitelist"
                // initialValue={obj.whitelist_address.map((item) => ({
                //   whiteaddress: item.address,
                //   ACQamount: item.balance,
                // }))}
                initialValue={
                  obj.whitelist_address.length > 0
                    ? obj.whitelist_address.map((item) => ({
                        whiteaddress: item.address,
                        ACQamount: item.balance,
                      }))
                    : [{ whiteaddress: "", ACQamount: "" }]
                }
              >
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }, index) => (
                      <Row
                        key={key}
                        gutter={8}
                        style={{ width: "100%", alignItems: "baseline" }}
                      >
                        <Col span={12}>
                          <Form.Item
                            {...restField}
                            name={[name, "whiteaddress"]}
                            //rules={[{ required: true, message: "Missing first name" }]}
                          >
                            <Input
                              placeholder="Add Wallet Address"
                              size="large"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={10}>
                          <Form.Item
                            {...restField}
                            name={[name, "ACQamount"]}
                            // rules={[{ required: true, message: "Missing last name" }]}
                          >
                            <Input
                              placeholder="Enter ACQ amount"
                              size="large"
                              onKeyPress={onKeyPress}
                            />
                          </Form.Item>
                        </Col>
                        {index > 0 && (
                          <CloseOutlined
                            onClick={() => remove(name)}
                            style={{ color: "white" }}
                          />
                        )}
                      </Row>
                    ))}
                    <Form.Item>
                      <Text
                        size="sm"
                        style={{ color: "#33FFFF", cursor: "pointer" }}
                        onClick={() => add()}
                      >
                        Add extra wallet address
                      </Text>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Row>

            <Row gutter={24}>
              <Col span={8}>
                <Form.Item>
                  <Button
                    style={{ width: "100%", marginTop: "14px" }}
                    type="primary"
                    htmlType="submit"
                    size="large"
                    loading={editLoading}
                  >
                    Save changes
                  </Button>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item>
                  <ConfigProvider
                    theme={{
                      components: {
                        Button: {
                          defaultBorderColor: "red",
                          defaultColor: "red",
                          defaultHoverColor: "red",
                        },
                      },
                    }}
                  >
                    <Button
                      style={{ width: "100%", marginTop: "14px" }}
                      size="large"
                      onClick={() => {
                        //HandleDelete(obj.id);
                        showDeleteModal();
                      }}
                      loading={deleteLoader}
                    >
                      Delete IDO
                    </Button>
                  </ConfigProvider>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Row>
      </Row>
      <DeleteModal
        title="Delete IDO"
        text1="Are you sure you want to delete this IDO? You can’t undo this action."
        open={isModalOpen}
        onCancel={handleCancel}
        onDelete={() => {
          HandleDelete(obj.id);
          handleCancel();
        }}
        DeleteButtonName="Delete IDO"
      />
    </Layout>
  );
};

export default EditIDO;

const AddonText = ({
  text,
  small,
  medium,
}: {
  text: string;
  small?: boolean;
  medium?: boolean;
}) => {
  return (
    <div
      style={{
        width: small ? "40px" : medium ? "60px" : "140px",
        height: "100%",
      }}
    >
      <Text size="sm">{text}</Text>
    </div>
  );
};

interface FormValues {
  amount: string;
  price: string;
  token_name: string;
  wallet_address: string;
  network: string;
  token: string;
  exchange_tokens: string[];
  valuation?: string;
  total_supply?: string;
  initial_market_cap?: string;
  hard_cap?: string;
  platform_raise?: string;
  token_intro?: string;
  project_overview?: string;
  revenue_model?: string;
  website?: string;
  linked_in_url?: string;
  x_url?: string;
  telegram_url?: string;
  discord_url?: string;
  interest_start?: Dayjs;
  interest_end?: Dayjs;
  allocation_start?: Dayjs;
  allocation_end?: Dayjs;
  fcfs_start?: Dayjs;
  fcfs_end?: Dayjs;
  TGEpercentage: string;
  acquireFee: string;
  TgeDate: Dayjs;
  monthlyUnlockStart: string;
  tokenReleaseAmount: string;
  whitelist: WhitelistItem[];
}
type WhitelistItem = {
  whiteaddress: string;
  ACQamount: string;
};

function formatDate(date: Date | null | undefined) {
  if (!date || isNaN(date.getTime())) {
    return "";
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hour = String(date.getHours()).padStart(2, "0");
  const minute = String(date.getMinutes()).padStart(2, "0");
  const second = String(date.getSeconds()).padStart(2, "0");
  return `${year}-${month}-${day}T${hour}:${minute}:${second}`;
}

const validateDiscordUrl = (rule: any, value: any, callback: any) => {
  if (value && !value.includes("http://") && !value.includes("https://")) {
    callback('URL must start with "http://" or "https://"');
  } else {
    callback(); // Validation passed
  }
};

const convertFromUTC = (dateString: any) => {
  const date = new Date(dateString);
  return formatDate(date);
};

function formatDateToUTC(inputDate: any) {
  if (inputDate) {
    const date = new Date(inputDate);

    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const seconds = String(date.getUTCSeconds()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;

    return formattedDate;
  } else {
    return undefined;
  }
}

function compareArrays(
  newArray: WhitelistAddress[],
  oldArray: WhitelistAddress[]
): boolean {
  if (
    newArray.length === 1 &&
    newArray[0].address === "" &&
    newArray[0].balance === ""
  ) {
    return false;
  }

  if (newArray.length !== oldArray.length) {
    return true;
  }

  for (let i = 0; i < newArray.length; i++) {
    if (
      newArray[i].address !== oldArray[i].address ||
      newArray[i].balance !== oldArray[i].balance
    ) {
      return true;
    }
  }

  return false;
}
